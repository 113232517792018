<template>
<div class=" mx-auto">
  <div class="row">
      <MenuLateral/>
      <Loading :isLoading= "this.isLoading" />
      <div style="text-align:center;padding:60px 10px 10px 10px;">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-start-5 col-end-9">
            <p style="font-family:Roboto;font-weight:700;color:#223635;font-size:20px;">Captura de Solicitud de Crédito</p>
          </div>
          <div class="col-start-5 col-end-9">
            <StepProgress style="" :steps="mySteps" :current-step="currentStep" active-color="#bfd243" passive-color="#223635" icon-class="fa fa-check"></StepProgress>
          </div>
        </div>
        <p style="font-family:Roboto;font-weight:500;color:#223635;font-size:15px;">Llena los campos requeridos conforme se le sean solicitados</p>
      </div>
      <div class="col col-sm-9">
        <Destino v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="1" tab="monto" v-if="showTab == 'destino'" />
        <Monto v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="2" stepBack="0" tab="frecuencia" tabBack="destino" v-if="showTab == 'monto'" />
        <Frecuencia v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="3" stepBack="1" tab="plazo" tabBack="monto" v-if="showTab == 'frecuencia'" />
        <Plazo v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="4" stepBack="2" tab="cargo-publico-familiar" tabBack="frecuencia" v-if="showTab == 'plazo'" />
        <CargoPublicoFamiliar v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="5" stepBack="3" tab="cargo-publico" tabBack="plazo" :title="titleCargoPublico" v-if="showTab == 'cargo-publico-familiar'" />
        <CargoPublico v-on:event-tab="changeTab" v-on:event-tab-back="goToBack" step="6" stepBack="4" tab="''" tabBack="cargo-publico-familiar" :title="titleCargoPublico" action="reedirect" url="/cuenta/prestamo/declaracion/-1/-1/1" v-if="showTab == 'cargo-publico'" />
      </div>
  </div>
  <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
</div>
</template>

<script>
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Destino from "@/components/Cuenta/Prestamo/Forms/Destino.vue"
import Frecuencia from "@/components/Cuenta/Prestamo/Forms/Frecuencia.vue"
import Plazo from "@/components/Cuenta/Prestamo/Forms/Plazo.vue"
import CargoPublico from "@/components/Cuenta/Prestamo/Forms/CargoPublico.vue"
import CargoPublicoFamiliar from "@/components/Cuenta/Prestamo/Forms/CargoPublicoFamiliar.vue"
import Monto from "@/components/Cuenta/Prestamo/Forms/Monto.vue"
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';

import Swal from "sweetalert2";
import moment from "moment";
import { saveRequest,getAdmissionRequest } from "@/api/user";

export default {
  data() {
    return {
      showTab: "destino",
      titleCargoPublico: "",
      mySteps: ['', '', '', '', '', ''],
      currentStep: 0,
    };
  },
  name: "PrestamoComponent", //CambiarContraseña
  components: {
    MenuLateral,
    Destino,
    Frecuencia,
    Plazo,
    CargoPublico,
    CargoPublicoFamiliar,
    Monto,
    StepProgress,
    Loading
  },
  props: {
  },
  async mounted() {},
  async beforeMount() {
    if (this.$route.params.step != undefined && this.$route.params.form != undefined) {
      this.showTab = this.$route.params.form;
      this.currentStep = this.$route.params.step;
    }
  },
  methods: {
    changeTab(event) {
      if (event.url != "") {
        // window.location.href = '/cuenta/prestamo/declaracion';
        this.$router.push({path:"/cuenta/prestamo/declaracion"});
      } else {
        this.showTab = event.tab;
        this.currentStep = event.step;
        this.titleCargoPublico = event.title;
      }
    },
    goToBack(event) {
      if (event.url != "") {
        // window.location.href = '/cuenta/prestamo/declaracion';
        this.$router.push({path:"/cuenta/prestamo/declaracion"});
      } else {
        this.showTab = event.tabBack;
        this.currentStep = event.stepBack;
        this.titleCargoPublico = event.title;
      }
    }
  },
};
</script>

<style>
.mycontent-left {
  border-right: 1px dashed #333;
  height: 250px;
}
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.step-progress__bar {
  margin-bottom: 20px !important;
  height: 0px !important
}

.step-progress__wrapper-after, .step-progress__wrapper-before {
  height: 3px !important;
}

.step-progress__step {
  --activeBorder: 2px !important;
  --passiveBorder: 2px !important;
}
.step-progress__step span {
  font-size: 12px;
}

.step-progress__step:after {
  width: 30px !important;
  height: 30px !important;
}

.ico{
    width:100px;
    display:block;
    height:20px;
    background:black;
    color:white;
    text-decoration:none;
    padding-left:20px;
}
.ico:before{
    content: '';
    background:url('https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSj3XfIpf-OOJRCcWz4iN2CU3qMKVVbj0p0bRvKNGVo1U9pk_8ZIlyR8pWreA');
    background-size:cover;
        position:absolute;
    width:20px;
    height:20px;
    margin-left:-20px;
}

</style>