<template>
  <form @submit="next" style="padding:10px;">
    <label class="labelControl">¿En qué plazo desea pagar el préstamo?</label>
    <div class="grid grid-cols-12 gap-4">
      <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-4 col-start-5 col-end-9">
        <div class="">
          <input type="button" value="12 quincenas" class="buttonWithoutBackground"
            v-bind:style="{backgroundColor: term == '36' ? activeColorBackground : inactiveColorBackground, 
            color: term == '36' ? activeColorText : inactiveColorText}" v-on:click="term = '36'">
        </div>
        <div class="">
          <input type="button" value="24 quincenas" class="buttonWithoutBackground"
            v-bind:style="{backgroundColor: term == '50' ? activeColorBackground : inactiveColorBackground, 
            color: term == '50' ? activeColorText : inactiveColorText}" v-on:click="term = '50'">
        </div>
        <div class="">
          <input type="button" value="36 quincenas" class="buttonWithoutBackground"
            v-bind:style="{backgroundColor: term == '60' ? activeColorBackground : inactiveColorBackground, 
            color: term == '60' ? activeColorText : inactiveColorText}" v-on:click="term = '60'">
        </div>
      </div>
      <div class="col-start-5 col-end-9">
        <button class="buttonNext">Continuar</button>
      </div>
      <div class="col-start-5 col-end-9">
        <input type="button" value="Atrás" v-on:click="goToBack" class="buttonBack" />
      </div>
    </div>
  </form>
</template>

<script>
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false,
      term: null,
      activeColorText: "#fff",
      inactiveColorText: "#000",
      activeColorBackground: "#223635",
      inactiveColorBackground: "#fff",
    };
  },
  props: ['tab','tabBack','step', ,'stepBack'],
  components: {
    MenuLateral,
    Loading,
  },
  async mounted() {},
  async beforeMount() {
  },
  methods: {
    next() {
      this.$emit('event-tab', {tab: this.tab, tabBack: this.tabBack, title: '',url:'',step:this.step, stepBack:this.stepBack});
    },
    goToBack() {
      this.$emit('event-tab-back', {tab: this.tab, tabBack: this.tabBack, title: '',url:'',step:this.step, stepBack:this.stepBack});
    }
  },
};
</script>

<style>
.mycontent-left {
  border-right: 1px dashed #333;
  height: 250px;
}
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

form {
  text-align:center;
}

.form-group {
  text-align: center;
}

.inputControl {
  border: 1px solid #bfd243 !important;
  height: 30px !important;
  text-transform: uppercase;
  border-radius:5px;
}

.labelControl {
  font-size:16px;
  color:#000;
  font-weight:500;
  font-family: 'Roboto';
}

.buttonNext {
  background-color: #bfd243; /* Green */
  opacity: 0.8;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.buttonBack {
  background-color: #fff; /* Green */
  border: 1px solid #bfd243;
  border-radius: 5px;
  color: #bfd243;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family:Lato;
  widows: 10px;
  width: 100%;
  height: 30px;;
  cursor: pointer;
}

.buttonWithoutBackground {
  background-color: #fff; /* Green */
  opacity: 0.8;
  border: solid 1px;
  border-radius: 5px;
  border-color: #000;
  color: #000;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}
</style>