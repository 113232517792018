<template>
  <form @submit="next" style="padding:10px;">
    <label class="labelControl">¿Cuál es el monto que necesita?</label>
    <div class="grid grid-cols-12 gap-4" style="margin-top:20px;">
      <div class="col-start-5 col-end-9">
        <div class="range">
          <input type="range" id="priceElentRange" min="10000" max="20000" step="5000" v-model="range" style="cursor: pointer;">
        </div>
        <div class="grid grid-cols-3 gap-4" style="margin-top:10px;">
          <div style="text-align:left;">
            <p style="font-family:Roboto;font-weight:500;font-size:15px;">{{$filters.formatCurrency('10000')}}</p>
          </div>
          <div>
            <p style="font-family:Roboto;font-weight:500;font-size:15px;">{{$filters.formatCurrency('15000')}}</p>
          </div>
          <div style="text-align:right;">
            <p style="font-family:Roboto;font-weight:500;font-size:15px;">{{$filters.formatCurrency('20000')}}</p>
          </div>
        </div>
        <!-- <ul class="range-labels">
          <li class="active selected" v-on:click="range = 1">$0.00</li>
          <li v-on:click="range = 2">$10,000.00</li>
          <li v-on:click="range = 3">$15,000.00</li>
          <li v-on:click="range = 4">$20,000.00</li>
        </ul> -->
      </div>
      <div class="col-start-5 col-end-9" style="margin-top:20px;">
        <p style="font-family:Roboto;font-weight:500;font-size:30px;">{{$filters.formatCurrency(range)}}</p>
      </div>
      <div class="col-start-5 col-end-9">
        <button class="buttonNext">Continuar</button>
      </div>
      <div class="col-start-5 col-end-9">
        <input type="button" value="Atrás" v-on:click="goToBack" class="buttonBack" />
      </div>
    </div>
  </form>
</template>

<script>
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false,
      gender: null,
      activeColorText: "#fff",
      inactiveColorText: "#000",
      activeColorBackground: "#223635",
      inactiveColorBackground: "#fff",
      range: "10000",
    };
  },
  name: "Monto",
  components: {
    MenuLateral,
    Loading,
  },
  props: ['tab','tabBack','step', ,'stepBack'],
  async mounted() {
    
  },
  async beforeMount() {
  },
  methods: {
    next() {
      this.$emit('event-tab', {tab: this.tab, tabBack: this.tabBack, title: '',url:'',step:this.step, stepBack:this.stepBack});
    },
    goToBack() {
      this.$emit('event-tab-back', {tab: this.tab, tabBack: this.tabBack, title: '',url:'',step:this.step, stepBack:this.stepBack});
    },
    currency(value) {
      const options2 = { style: 'currency', currency: 'MXN' };
      //const formatter = new Intl.NumberFormat('es-MX', options2);
      const formatter = new Intl.NumberFormat('en-US', options2);

      var resultado = Number(value.replace(/[^0-9.-]+/g,"")).toString();
      
      if(!value.includes(".")){
          resultado = value + ".00"
      }
      
      //if(parseFloat(value) < 9999.99){
      //    resultado = value.replace(",","")
      //}
      if(!resultado.includes(",")){
          resultado = resultado.replace(",","")//formatter.format(value)
      }
      resultado = formatter.format(resultado)
      
      resultado = resultado.replace('MX$','')
      
      if (resultado =="NaN" || resultado < 0) {
          resultado = ""
      }
      return '$' + resultado;
    },
  },
};
</script>

<style>
.mycontent-left {
  border-right: 1px dashed #333;
  height: 250px;
}
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

form {
  text-align:center;
}

.form-group {
  text-align: center;
}

.inputControl {
  border: 1px solid #bfd243 !important;
  height: 30px !important;
  text-transform: uppercase;
  border-radius:5px;
}

.labelControl {
  font-size:16px;
  color:#000;
  font-weight:500;
  font-family: 'Roboto';
}

.buttonNext {
  background-color: #bfd243; /* Green */
  opacity: 0.8;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.buttonBack {
  background-color: #fff; /* Green */
  border: 1px solid #bfd243;
  border-radius: 5px;
  color: #bfd243;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family:Lato;
  widows: 10px;
  width: 100%;
  height: 30px;;
  cursor: pointer;
}

.buttonWithoutBackground {
  background-color: #fff; /* Green */
  opacity: 0.8;
  border: solid 1px;
  border-radius: 5px;
  border-color: #000;
  color: #000;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.range {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 5px;
}

.range input {
  width: 100%;
  position: absolute;
  top: 2px;
  height: 0;
  -webkit-appearance: none;
}
.range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #bfd243;
  cursor: pointer;
  border: 0 !important;
}
.range input::-moz-range-thumb {
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #bfd243;
  cursor: pointer;
  border: 0 !important;
}
.range input::-ms-thumb {
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #bfd243;
  cursor: pointer;
  border: 0 !important;
}
.range input::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #223635;
}
.range input::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #223635;
}
.range input::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #223635;
}
.range input:focus {
  background: none;
  outline: none;
}
.range input::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  margin: 18px -11px 0;
  padding: 0;
  list-style: none;
}
.range-labels li {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  color: #223635;
  font-size: 14px;
  cursor: pointer;
}
.range-labels li::before {
  position: absolute;
  top: -25px;
  right: 0;
  left: 0;
  content: "";
  margin: 0 auto;
  width: 9px;
  height: 9px;
  background: #223635;
  border-radius: 50%;
}
.range-labels .active {
  color: #bfd243;
}
.range-labels .selected::before {
  background: #bfd243;
}
.range-labels .active.selected::before {
  display: none;
}
</style>