<template>
  <form @submit="next" style="padding:10px;">
    <div class="grid grid-cols-12 gap-4">
      <div class="form-group col-start-5 col-end-9">
        <label class="labelControl">¿Cuál es el destino de su préstamo?</label>
        <select name="select" class="form-control inputControl">
          <option value="" selected>Seleccionar</option>
          <option value="">Liquidación de deudas</option>
          <option value="">Gastos de salud</option>
          <option value="">Bienes inmuebles y/o sus mejoras</option>
          <option value="">Compra de automóvil</option>
          <option value="">Gastos funerarios</option>
          <option value="">Gastos escolares</option>
          <option value="">Otros</option>
        </select>
      </div>
      <div class="col-start-5 col-end-9">
        <button class="buttonNext">Continuar</button>
      </div>
    </div>
  </form>
</template>

<script>
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  name: "Destino",
  components: {
    MenuLateral,
    Loading,
  },
  props: ['tab','step'],
  async mounted() {},
  async beforeMount() {
  },
  methods: {
    next() {
      this.$emit('event-tab', {tab: this.tab,title:'',url:'',step:this.step});
    },
  },
};
</script>

<style>
.mycontent-left {
  border-right: 1px dashed #333;
  height: 250px;
}
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.form-group {
  text-align: center;
}

.inputControl {
  border: 1px solid #bfd243 !important;
  height: 30px !important;
  text-transform: uppercase;
  border-radius:5px;
}

.labelControl {
  font-size:16px;
  color:#000;
  font-weight:500;
  font-family: 'Roboto';
}

.buttonNext {
  background-color: #bfd243; /* Green */
  opacity: 0.8;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.buttonBack {
  background-color: #fff; /* Green */
  border: 1px solid #bfd243;
  border-radius: 5px;
  color: #bfd243;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family:Lato;
  widows: 10px;
  width: 100%;
  height: 30px;;
  cursor: pointer;
}

.buttonWithoutBackground {
  background-color: #fff; /* Green */
  opacity: 0.8;
  border: solid 1px;
  border-radius: 5px;
  border-color: #000;
  color: #000;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  font-family:'Roboto';
  widows: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}
</style>